<template>
 <div class="content">
   <app-cells position="between">
     <app-cells :indent="false">
       <h1 class="title title--theme">Договоры</h1>
       <router-link v-if="userIsAdmin" :to="{ name: 'contracts-stat' }" class="btn btn--link">
         <img src="@/assets/img/sidebar/stat.svg" alt="Stat icon">
         <span>Статистика</span>
       </router-link>
     </app-cells>
     <v-select
       v-if="userIsAdmin"
       :reduce="item => item.id"
       :options="delegation_options"
       :filterable="true"
       :clearable="false"
       v-model="user_delegation_id"
       @input="onSetDelegation"
       label="name"
       placeholder="Выберите делегацию"
       class="select select--minw"
     >
       <template #open-indicator>
         <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
           <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
           <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
         </svg>
       </template>
       <template #option="option">
         <div class="select__item d-center">{{ option.name }}</div>
       </template>
       <template #selected-option="option">
         <div class="selected d-center">{{ option.name }}</div>
       </template>
       <template #no-options>Ничего не найдено</template>
     </v-select>
   </app-cells>
   <h2 class="title title--medium">Договоры юридических лиц</h2>
   <app-cells position="start">
     <router-link :to="{ name: 'contracts-legal-create' }" class="btn btn--link btn--link-icon">
       <span>Добавить договор юридического лица</span>
     </router-link>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiterLegal"
     :count="table_legal.items.length"
     :page="pagination_legal.page"
     :all="pagination_legal.count"
   />
   <v-client-table
     @row-click="onRowLegalClick"
     :data="table_legal.items"
     :columns="table_legal.columns"
     :options="table_legal.options"
     ref="tableDefaultLegal"
     class="table-default table-default--dynamic table-default--indent"
   >
     <template #participants_count="props">
       <span>Всего: {{ props.row.participants_count }}</span><br>
       <span>По квоте: {{ props.row.count_qouta }}</span><br>
       <span>Вне квоты: {{ props.row.count_qouta_out }}</span>
     </template>
     <template #total_amount="props">
       {{ new Intl.NumberFormat('ru-RU').format(props.row.total_amount) }} ₽
     </template>
     <template #paid="props">
       {{ new Intl.NumberFormat('ru-RU').format(props.row.paid) }} ₽
     </template>
   </v-client-table>
   <paginate
     v-if="pagination_legal.pages > 1"
     v-model="pagination_legal.page"
     :page-count="pagination_legal.pages"
     :clickHandler="onClickPaginationLegal"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
   <h2 class="title title--medium">Договоры физических лиц</h2>
   <app-cells position="start">
     <router-link :to="{ name: 'contracts-individual-create' }" class="btn btn--link btn--link-icon">
       <span>Добавить договор физического лица</span>
     </router-link>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiterIndividual"
     :count="table_individual.items.length"
     :page="pagination_individual.page"
     :all="pagination_individual.count"
   />
   <v-client-table
     @row-click="onRowIndividualClick"
     :data="table_individual.items"
     :columns="table_individual.columns"
     :options="table_individual.options"
     ref="tableDefaultIndividual"
     class="table-default table-default--dynamic"
   >
     <div slot="full_name" slot-scope="props" class="table-default__left">
       {{ props.row.full_name }}
     </div>
     <template #rate="props">
       {{ new Intl.NumberFormat('ru-RU').format(props.row.rate.amount) }} ₽
     </template>
   </v-client-table>
   <paginate
     v-if="pagination_individual.pages > 1"
     v-model="pagination_individual.page"
     :page-count="pagination_individual.pages"
     :clickHandler="onClickPaginationIndividual"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
 </div>
</template>

<script>
import {getContractsIndividual, getContractsLegal, getDelegations, patchUser} from '@/http'

export default {
  name: 'ContractsList',
  data() {
    return {
      table_individual: {
        items: [],
        columns: ['pk', 'full_name', 'rate', 'status.name'],
        options: {
          headings: {
            'pk': 'ID',
            'full_name': 'ФИО',
            'rate': 'Сумма',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного договора',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      table_legal: {
        items: [],
        columns: ['pk', 'org_name_short', 'participants_count', 'total_amount', 'paid', 'status.name'],
        options: {
          headings: {
            'pk': 'ID',
            'org_name_short': 'Организация',
            'participants_count': 'Участников',
            'total_amount': 'Сумма договора',
            'paid': 'Оплачено',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного договора',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      pagination_legal: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit_legal: 20,
      pagination_individual: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit_individual: 20,
      delegation_options: [],
      user_delegation_id: null,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    this.fetchItemsLegal()
    this.fetchItemsIndividual()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    fetchItemsLegal() {
      getContractsLegal(this.pagination_legal.page, this.table_limit_legal)
        .then(response => {
          this.table_legal.items = response.data.results
          this.pagination_legal.pages = response.data.pages
          this.pagination_legal.count = response.data.count
        })
    },
    fetchItemsIndividual() {
      getContractsIndividual(this.pagination_individual.page, this.table_limit_individual)
        .then(response => {
          this.table_individual.items = response.data.results
          this.pagination_individual.pages = response.data.pages
          this.pagination_individual.count = response.data.count
        })
    },
    onRowIndividualClick(row) {
      this.$router.push({ name: 'contracts-individual-edit', params: { id: row.row.pk } })
    },
    onRowLegalClick(row) {
      this.$router.push({ name: 'contracts-legal-edit', params: { id: row.row.pk } })
    },
    onUpdateLimiterLegal(data) {
      this.table_limit_legal = data
      this.pagination_legal.page = 1
      this.$refs.tableDefaultLegal.setLimit(this.table_limit_legal)
      this.fetchItemsLegal()
    },
    onClickPaginationLegal(page) {
      this.pagination_legal.page = page
      this.fetchItemsLegal()
    },
    onUpdateLimiterIndividual(data) {
      this.table_limit_individual = data
      this.pagination_individual.page = 1
      this.$refs.tableDefaultIndividual.setLimit(this.table_limit_individual)
      this.fetchItemsIndividual()
    },
    onClickPaginationIndividual(page) {
      this.pagination_individual.page = page
      this.fetchItemsIndividual()
    },
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItemsLegal()
          this.fetchItemsIndividual()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
  }
}
</script>

<style scoped>

</style>
